import React from 'react';
import ReactPlayer from 'react-player/lazy';
import LazyLoad from 'react-lazyload';
import { sibiEmmaBase64Img } from '../../images/landingBase64';
import useStyles from '../../styles/landing/style';
import { Box } from '@material-ui/core';

const FallbackComponentWhileLoadingVideo = ({ videoPreviewImage = sibiEmmaBase64Img }) => {
  return (
    <div
      className="loading-video"
      style={{
        width: '100%',
        height: '100%',
        minHeight: '400px',
        borderRadius: 12,
        overflow: 'hidden',
        // background: "red",
        position: 'absolute',
        zIndex: 300,
        backgroundImage: `url("${videoPreviewImage}")`,
        backgroundSize: 'cover',
        WebkitFilter: 'blur(15px)',
        MozFilter: 'blur(15px)',
        OFilter: 'blur(15px)',
        MsFilter: 'blur(15px)',
        filter: 'blur(15px)',
        WebkitTransform: 'scale(1.2, 1.2)',
        MozTransform: 'scale(1.2, 1.2)',
        OTransform: 'scale(1.2, 1.2)',
        MsTransform: 'scale(1.2, 1.2)',
        transform: 'scale(1.2, 1.2)'
      }}
    ></div>
  );
};

const VideoPlayerRaw = ({
  url,
  loop = false,
  videoPreviewImage = sibiEmmaBase64Img,
  defaultHeight = 380,
  controls = true
}) => {
  const [isVideoReady, setIsVideoReady] = React.useState(false);
  const classes = useStyles();
  return (
    <>
      <Box
        style={{
          width: '100%',
          height: 'auto',
          borderRadius: 12,
          overflow: 'hidden',
          position: 'relative'
          // minHeight: `${defaultHeight}px`,
        }}
        className={classes.videoLazyContainer}
      >
        {isVideoReady === false && (
          <FallbackComponentWhileLoadingVideo videoPreviewImage={videoPreviewImage} />
        )}
        <LazyLoad height={defaultHeight} offset={100}>
          <ReactPlayer
            url={url}
            playing={true}
            muted={true}
            controls={controls}
            loop={loop}
            width="100%"
            height="100%"
            onReady={() => {
              setIsVideoReady(true);
            }}
          />
        </LazyLoad>
      </Box>
    </>
  );
};

export default VideoPlayerRaw;
